import { Howl } from "howler"
import { useEffect, useRef, useState } from "react"
import { getExt } from "shared/utils/getExt"

export default function useAudioController({ beat, playingBeat, setPlayingBeat }) {
  const [isLoading, setIsLoading] = useState(false)
  const beatEntity = useRef(null)
  const setup = () => {
    if (beatEntity.current || beat === null) return
    const ext = getExt(beat?.name) ? "" : ".mp3"
    beatEntity.current = new Howl({
      src: `beats/${beat.name + ext}`,
      loop: true,
      usingWebAudio: false,
      autoSuspend: false,
      html5: true,
      ctx: true,
      preload: false,
      onload() {
        setIsLoading(false)
      },
      onend() {
        console.log("Finished!")
      },
      onseek() {
        console.log("seeking...")
      }
    })
  }

  const click = () => {
    if (!beatEntity.current) return
    if (playingBeat === beat.name) {
      beatEntity.current.stop()
      beatEntity.current.play()
    } else {
      setIsLoading(true)
      setPlayingBeat(beat.name)
    }
  }

  const keyPress = () => {
    if (playingBeat === beat?.name) {
      beatEntity?.current?.stop()
      beatEntity?.current?.play()
    } else {
      setIsLoading(true)
      setPlayingBeat(beat?.name)
    }
  }

  useEffect(() => {
    if (playingBeat !== beat?.name && beatEntity.current) {
      beatEntity.current.stop()
      beatEntity.current.unload()
      setIsLoading(false)
    } else if (beatEntity.current) {
      beatEntity.current.play()
    }
  }, [playingBeat])

  useEffect(setup, [])

  return { isLoading, click, keyPress }
}
