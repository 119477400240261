import React from "react"
import css from "./HomeBackgorund.module.scss"

export const HomeBackground = ({ children }) => {
  return (
    <div className={css.wrapper} tabIndex={0} id="appContainer">
      <div className={css.contentWrapper} id="contentWrapper">
        <div className={css.noize} />
        <div className={css.content}>{children}</div>
      </div>
    </div>
  )
}
