import React, { useState, useEffect } from "react"
import cn from "classnames"
import BeatCard from "./BeatCard"
import css from "./Beats.module.scss"

export default function Beats({ playingBeat, setPlayingBeat, beats, stop }) {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return (
    <div className={cn(css.beatsWrapper, isLoaded && css.beatsWrapper__visible)} id="beatsWrapper">
      {beats.map((beat) => (
        <BeatCard
          key={beat.order}
          beat={beat}
          playingBeat={playingBeat}
          setPlayingBeat={setPlayingBeat}
          letter={beat.letter}
        />
      ))}
      <BeatCard letter="STOP" stop={stop} />
    </div>
  )
}
