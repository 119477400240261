import React, { useState, useCallback, useEffect } from "react"
import OutsideClickHandler from "react-outside-click-handler"
import Beats from "./components/Beats/Beats"
import { HomeFooter } from "pages/Home/components/HomeFooter/HomeFooter"
import beats from "shared/constants/beats.json"
import { HomeBackground } from "./components/HomeBackground/HomeBackground"
import { Title } from "./components/Title/Title"
import useKeyPress from "shared/hooks/useKeyPress"

export default function Home() {
  const [playingBeat, setPlayingBeat] = useState(null)
  const [isDesktop, setIsDesktop] = useState(null)
  const { keyPressEvent } = useKeyPress({ beats, playingBeat, setPlayingBeat })

  const stop = useCallback(() => {
    setPlayingBeat(null)
  }, [setPlayingBeat])

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      setIsDesktop(true)
    }
    document.getElementById("appContainer").focus()
  }, [isDesktop])

  return (
    <div tabIndex={0} onKeyDown={keyPressEvent}>
      <HomeBackground>
        <Title />
        <OutsideClickHandler onOutsideClick={stop}>
          <Beats
            playingBeat={playingBeat}
            isNotFirstEnter={true}
            setPlayingBeat={setPlayingBeat}
            beats={beats}
            stop={stop}
          />
        </OutsideClickHandler>
        <HomeFooter />
      </HomeBackground>
    </div>
  )
}
