import React from "react"

import css from "./HomeFooter.module.scss"
import { DownloadLink } from "./DownloadLink"

export const HomeFooter = () => {
  return (
    <div className={css.outsideWrapper} id="logosWrapper">
      <DownloadLink />
    </div>
  )
}
