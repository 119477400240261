import React from "react"

import css from "./Title.module.scss"

export const Title = () => {
  return (
    <div className={css.title} id="blastTitle">
      JASTER — KOMHATA
    </div>
  )
}
