import { useEffect, useState } from "react"
import useAudioController from "./useAudioController"

export default function useKeyPress({ beats, playingBeat, setPlayingBeat }) {
  const [tabbedLetter, setTabbedLetter] = useState(null)
  const [beat, setBeat] = useState(null)
  const { keyPress } = useAudioController({ beat, playingBeat, setPlayingBeat })

  const keyPressEvent = (event) => {
    setTabbedLetter(event.key)
    const res = beats.find((e) => e.letter.toLocaleLowerCase() === event.key)
    if (res && beat !== res) {
      setPlayingBeat(null)
      setBeat(res)
    }
  }

  useEffect(() => {
    keyPress()
  }, [tabbedLetter, beat])

  return { keyPressEvent }
}
