import React, { useCallback } from "react"
import PropTypes from "prop-types"
import css from "./Beats.module.scss"
import cn from "classnames"
import Preloader from "features/Preloader/Preloader"
import useAudioController from "shared/hooks/useAudioController"

export default function BeatCard({ beat, playingBeat, setPlayingBeat, stop, letter }) {
  const { isLoading, click } = useAudioController({ beat, playingBeat, setPlayingBeat })

  const currentSongIsPlaying = playingBeat && playingBeat === beat.name

  const handleClick = useCallback(() => {
    if (stop && typeof stop === "function") {
      stop()
    } else {
      click()
    }
  }, [stop, click])

  return (
    <button
      type="button"
      className={cn(css.beatCard, currentSongIsPlaying && css.beatCardActive)}
      onClick={handleClick}
    >
      {isLoading && <Preloader />}
      <span className={css.beatCardLetter}>{letter}</span>
    </button>
  )
}

BeatCard.defaultProps = {
  beat: null,
  playingBeat: "",
  setPlayingBeat: null,
  stop: null
}

BeatCard.propTypes = {
  beat: PropTypes.any,
  playingBeat: PropTypes.string,
  setPlayingBeat: PropTypes.func,
  stop: PropTypes.func
}
